<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <!--          <img id="santa" src="./assets/santa_hat.png" alt="santa logo" />-->
          <img class="logo" src="./assets/main_logo.svg" alt="logo" />
          <br />
          <span> SIP panel </span>
        </a>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              Від &nbsp;
              <date-picker
                v-model="time1"
                valueType="date"
                @change="change_period(0), calcDiff(time1, time2)"
                title-format="DD-MM-YY"
                format="DD-MM-Y"
                :disabled-date="(date) => date >= new Date()"
              ></date-picker>
            </li>
            <li class="nav-item ml-1">
              &nbsp; до &nbsp;
              <date-picker
                v-model="time2"
                @change="change_period(0), calcDiff(time1, time2)"
                type="date"
                title-format="DD-MM-YY"
                format="DD-MM-Y"
                :disabled-date="(date) => date > d_date"
              >
              </date-picker>
            </li>
          </ul>

          <form class="d-flex">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Пошук за номером"
              aria-label="Пошук..."
              v-model="search"
              @change="by_number(search)"
            />
            <button
              class="btn btn-outline-success"
              @click.prevent="by_number(search)"
            >
              Шукати
            </button>
          </form>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="row mt-4">
        <h1 class="col-12">{{ info }}</h1>
        <div class="col-md-6" v-if="this.brands.length > 0">
          <apexchart
            type="pie"
            :options="chartOptions"
            :series="this.brands"
          ></apexchart>

          <div class="row brands">
            <h4 class="col-12 text-center">
              Дані по операторах за обраний період
            </h4>
            <div class="col-md-4">
              <img class="logo" src="./assets/life.png" alt="life logo" />
              <br />
              Life <strong>{{ numberWithSpaces(this.brands[0]) }}</strong>
            </div>
            <div class="col-md-4">
              <img
                class="logo"
                src="./assets/kyivstar_logo.png"
                alt="kyivstar_logo"
              />
              <br />
              Kyivstar <strong>{{ numberWithSpaces(this.brands[1]) }}</strong>
            </div>
            <div class="col-md-4">
              <img
                class="logo"
                src="./assets/vodafone.png"
                alt="vodafone logo"
              />
              <br />
              Vodafone <strong>{{ numberWithSpaces(this.brands[2]) }}</strong>
            </div>
          </div>
        </div>

        <div class="col-md-6 align-items-center justify-content-center h-100">
          <div class="loaded" v-if="this.phones.length > 0">
            <span v-if="difDays > 0">
              Отримано даних за період {{ difDays }} дн:
            </span>
            <span v-else> Отримано за добу: </span>

            <strong>{{ numberWithSpaces((this.pages - 1)  * 100) }}</strong>
            дзвінків

            <b-table
              striped
              hover
              id="my-table"
              :busy.sync="isBusy"
              :items="phones"
              small
              :sticky-header="true"
            ></b-table>

            <div class="overflow-auto">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                align="center"
                @change="change_period"
              ></b-pagination>

              <p class="mt-3">
                Поточна сторінка: <strong>{{ currentPage }}</strong> з
                <strong>{{ numberWithSpaces(Math.ceil(this.pages)) }}</strong>
              </p>
            </div>
          </div>
          <div class="load" v-else>
            Завантажую дані з сервера <br />
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import navbar from "./components/navbar.vue";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/uk";

export default {
  name: "App",
  components: { DatePicker },
  data() {
    return {
      info: null,
      search: null,
      phones: [],
      brands: [],
      perPage: 100,
      difDays: 0,
      currentPage: 1,
      pages: 0,
      items: [],
      isBusy: false,
      time1: new Date(),
      time2: new Date(),
      test: null,
      d_date: new Date(),

      chartOptions: {
        colors: ["#FFC40C", "#229FFF", "#E60000"],
        chart: {
          width: "100%",
          type: "pie",
        },
        labels: ["Life", "Kyivstar", "Vodafone"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    rows() {
      return this.pages  * 100;
    },
    tableData() {
      return this.phones;
    },
  },
  methods: {
    numberWithSpaces: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    custom_date(d) {
      let dd = new Date(d),
        ye = dd.getFullYear(),
        mo = dd.getMonth(),
        day = dd.getDate();
      return `${ye}-${mo + 1}-${day}`;
    },
    format_serverDate(it) {
      let dd = it.date.split(".")[0],
        hours = dd.split(" ")[1],
        ddAr = dd.split(" ")[0].split("-");
      return {
        phone: it.phone,
        date: `${ddAr[2]}-${ddAr[1]}-${ddAr[0]} ( ${hours} )`,
      };
    },
    change_period(page) {
      //this.phones = [];
      //this.brands = [];
      this.isBusy = true;
      this.info = null;
      if (page){
        this.currentPage = page
      }
      axios
        .get(
          "/brands/" +
            this.custom_date(this.time1) +
            "/" +
            this.custom_date(this.time2) +
            "/"
        )
        .then((r) => {
          this.brands = Object.values(r.data).map((t) => {
            return Number(t);
          });
        });

      axios
        .get(
          "/period/" +
            this.custom_date(this.time1) +
            "/" +
            this.custom_date(this.time2) +
            "/" +
            this.currentPage
        )
        .then((resp) => {
          this.phones = resp.data[1].map((it) => this.format_serverDate(it));
          this.pages = Number(resp.data[0]) + 1;
          this.isBusy = false;
          this.$root.$emit("bv::refresh::table", "my-table");
        });
    },
    by_number(num) {
      if (num.length === 12) {
        this.info = null;
        this.phones = [];
        axios
          .get(
            "/phone/" +
              num +
              "/?from=" +
              this.custom_date(this.time1) +
              "&to=" +
              this.custom_date(this.time2)
          )
          .then((resp) => {
            this.phones = resp.data[1].map((it) => this.format_serverDate(it));
            this.pages = Number(resp.data[0]) + 1;
          });
      } else if (num.length === 0 || num === "") {
        this.info = null;
        this.change_period();
      } else {
        this.info = "Номер має не вірну довжину, має  бути 12 символів ";
      }
    },
    calcDiff(from, to) {
      const date1 = new Date(from);
      const date2 = new Date(to);
      const diffTime = Math.abs(date2 - date1);
      this.difDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
  },
  mounted: function () {
    this.calcDiff(this.time1, this.time2);
    this.d_date.setDate(this.d_date.getDate() + 1);
    this.change_period();

    // window.setInterval(() => {
    //   this.change_period();
    // }, 30000);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 60px;
}

.logo {
  height: 60px;
}

.navbar-brand {
  position: relative;
  span {
    font-size: 16px;
    color: orange;
  }
  #santa {
    position: absolute;
    width: 90px;
    left: -37px;
    top: -5px;
  }
}

.brands {
  img {
    height: 40px;
    padding-bottom: 10px;
  }
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 70vh!important;
}

.lds-spinner {
  color: #2c3e50;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #2c3e50;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
